import { Chip, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'date-fns';
import React from 'react';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';
import { ICompanyCreditDashboard } from '../../../models/OverviewModel';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import Gauge from '../../Graph/CreditGauge/Gauge';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';

const useStyles = makeStyles(styles);

const CompanyCreditComponent: React.FC<IProps> = (props) => {
    const [openReasonsForScore, setOpenReasonsForScore] = React.useState(false);

    const classes = useStyles();
    const creditScore = props.companyCredit.CreditScore;

    const percentage = ((creditScore - 400) / 350) * 100;

    const companyStatus = props.companyCredit.CompanyStatus;

    let showReasonsForScore = false;

    if (props?.companyCredit?.RiskEvaluation) {
        showReasonsForScore = Object.values(props.companyCredit.RiskEvaluation).filter((reason) => !!reason).length > 0;
    }

    React.useEffect(() => {
        const reasons = Object.values(props.companyCredit.RiskEvaluation);

        if (reasons.length > 0) {
            for (let i = 0; i < reasons.length; i++) {
                const reason = reasons[i];

                if (reason === 'Company Performance - Company has Commercial Judgement(s)' && !openReasonsForScore) {
                    setOpenReasonsForScore(true);
                    break;
                }
            }
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Card>
            <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                    <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Company Credit Data</p>
                <h3 className={classes.cardTitle}>{props.companyName}</h3>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={5} md={5}>
                        <Gauge gaugePercentage={percentage} label={props.companyCredit.CreditScore}></Gauge>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                        <Table
                            tableData={[
                                ['Credit Score', props.companyCredit.CreditScore],
                                [
                                    'Vat Registered',
                                    props.companyCredit.VatRegistered === true ? (
                                        <CheckCircleIcon style={{ color: green[500] }} />
                                    ) : (
                                        <CancelIcon color="error" />
                                    )
                                ],
                                [
                                    'Company Status',
                                    companyStatus === 'IN BUSINESS' || companyStatus === 'DEREGISTRATION PROCESS' ? (
                                        companyStatus
                                    ) : (
                                        <Chip
                                            label={companyStatus}
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}
                                            size="small"
                                        />
                                    )
                                ],
                                ['Number of Directors', props.companyCredit.NumberOfDirectors]
                            ]}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                        <GridContainer spacing={0} justify="center">
                            <GridItem xs={12} sm={6} md={3} lg={2} padding="0">
                                <div
                                    style={{
                                        backgroundColor: '#d13425',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: '#505050',
                                        minWidth: 85
                                    }}
                                >
                                    <p style={{ margin: 0 }}>Very High</p>
                                    <p style={{ margin: 0 }}>{'<= 625'}</p>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2} padding="0">
                                <div
                                    style={{
                                        backgroundColor: '#db5d3d',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: '#505050',
                                        minWidth: 85
                                    }}
                                >
                                    <p style={{ margin: 0 }}>High</p>
                                    <p style={{ margin: 0 }}>626-640</p>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2} padding="0">
                                <div
                                    style={{
                                        backgroundColor: '#ffb32f',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: '#505050',
                                        minWidth: 85
                                    }}
                                >
                                    <p style={{ margin: 0 }}>Average</p>
                                    <p style={{ margin: 0 }}>641-654</p>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2} padding="0">
                                <div
                                    style={{
                                        backgroundColor: '#71c07c',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: '#505050',
                                        minWidth: 85
                                    }}
                                >
                                    <p style={{ margin: 0 }}>Low</p>
                                    <p style={{ margin: 0 }}>655-666</p>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={3} lg={2} padding="0">
                                <div
                                    style={{
                                        backgroundColor: '#42a257',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: '#505050',
                                        minWidth: 85
                                    }}
                                >
                                    <p style={{ margin: 0 }}>Minimum</p>
                                    <p style={{ margin: 0 }}>{'> 666'}</p>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                {showReasonsForScore && (
                    <div style={{ marginTop: 30 }}>
                        <ExpansionPanel
                            expanded={openReasonsForScore}
                            onClick={() => setOpenReasonsForScore((prev) => !prev)}
                        >
                            <ExpansionPanelSummary
                                className="no-margin"
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Reasons for score:
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ flexDirection: 'column' }}>
                                    {Object.keys(props.companyCredit.RiskEvaluation).map((key, index) => {
                                        const isJudgementEntry =
                                            // @ts-ignore
                                            props.companyCredit.RiskEvaluation[key] ===
                                            'Company Performance - Company has Commercial Judgement(s)';

                                        return (
                                            <p
                                                style={{
                                                    marginTop: index === 0 ? 0 : 5,
                                                    fontSize: 12,
                                                    color: isJudgementEntry ? 'red' : 'black',
                                                    fontWeight: isJudgementEntry ? 'bold' : 'normal'
                                                }}
                                                key={index}
                                            >
                                                {/* @ts-ignore */}
                                                {props.companyCredit.RiskEvaluation[key]}
                                            </p>
                                        );
                                    })}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                )}
            </CardBody>
            <CardFooter />
        </Card>
    );
};

export default CompanyCreditComponent;

interface IProps {
    companyCredit: ICompanyCreditDashboard;
    companyName: string;
}
